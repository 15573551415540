import React, { Component, Suspense } from "react";
import logo from "./logo.svg";

const Loader = () => (
  <div className="App">
    <img src={logo} className="App-logo" alt="logo" />
    <div>loading...</div>
  </div>
);

export default Loader;
