import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobeAsia } from "@fortawesome/free-solid-svg-icons";
import STMlogo from "./Images/logo-full.png";
import "./style.css";
import { useTranslation, withTranslation, Trans } from "react-i18next";

const Comp = (props) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);
  const { t, i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  const imgStyle = {
    height: "50px",
    filter: "invert(100%)",
  };
  const dropDownMenuStyle = {
    backgroundColor: "#ccc",
  };

  function SmoothVerticalScrolling(e, time, where) {
    var eTop = e.getBoundingClientRect().top;
    var eAmt = eTop / 100;
    var curTime = 0;
    while (curTime <= time) {
        window.setTimeout(SVS_B, curTime, eAmt, where);
        curTime += time / 100;
    }
  }

  function SVS_B(eAmt, where) {
    if(where == "center" || where == "")
        window.scrollBy(0, eAmt / 2);
    if (where == "top")
        window.scrollBy(0, eAmt);
  }

  return (
    <div>
      <Navbar dark expand="md" className="bg-dark-nav">
        <NavbarBrand href="/">
          <img style={imgStyle} src={STMlogo} alt="SANTIMARKETING Logo" />
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse isOpen={!collapsed} navbar>
          <Nav className="ml-auto" navbar>
            {/* <NavItem>
              <NavLink href={process.env.PUBLIC_URL + "/"}>
                {t("NavigationBar.Home")}
              </NavLink>
            </NavItem> */}
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                {t("NavigationBar.Products")}
              </DropdownToggle>
              <DropdownMenu
                className="dropdown-lang"
                style={dropDownMenuStyle}
                right
              >
                {t("Products.Groups", { returnObjects: true }).map((group) => {
                return (
                  // render dropdown option for each product type.
                  <DropdownItem  href={"#section-"+t(group.id)} /*onClick={()=>SmoothVerticalScrolling(document.getElementById("section-"+t(group.id)), 275, "top")}*/> {group.name} 
                  </DropdownItem>
                );
              })}
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              {/* <NavLink href={process.env.PUBLIC_URL + "/AboutUs"}> */}
              <NavLink href="#about">{t("NavigationBar.AboutUs")}</NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <FontAwesomeIcon icon={faGlobeAsia} />{" "}
                {t("NavigationBar.Language")}
              </DropdownToggle>
              <DropdownMenu
                className="dropdown-lang"
                style={dropDownMenuStyle}
                right
              >
                <DropdownItem onClick={() => changeLanguage("en")}>
                  English
                </DropdownItem>
                <DropdownItem onClick={() => changeLanguage("th")}>
                  ไทย
                </DropdownItem>
                {/* <DropdownItem divider />
                  <DropdownItem>Reset</DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Comp;
