import React, { Component } from "react";
import Navbar from "../components/Navbar";
import Jumbotron from "../components/Jumbotron";
import Footer from "../components/Footer";
import "../index.css";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import Background from "../components/Images/test.jpg";

export default function () {
  const { t, i18n } = useTranslation();

  var products = t("Products.Card", { returnObjects: true });
  var textBox = { padding: "10px 50px 10px 20px" };
  return (
    <div id="about">
      <div
        className="about-bg"
        style={{
          backgroundImage: `url(${Background})`,
        }}
      >
        <div className="about-overlay">
          <div className="section-slider">
            <h2 style={{ paddingBottom: "30px" }}>About US</h2>
            <div>
              <div className="about-text">{t("AboutUs.intro")}</div>
              <div className="about-text">{t("AboutUs.strength")}</div>
              {/* <div className="about-text"> {t("AboutUs.mission")} </div> */}
              {/* <div className="about-text"> {t("AboutUs.vision")} </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
