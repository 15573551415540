import React, { Component, useState } from "react";
import Card from "../components/Card";
import InfoModal from "../components/InfoModal";
import "../index.css";
import { useTranslation, withTranslation, Trans } from "react-i18next";

// import { Collapse, CardBody, Card, CardHeader, CardImg } from "reactstrap";
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";

const Comp = (props) => {
  const { t, i18n } = useTranslation();
  const { sliderName, filter, index } = props;
  const [position, setPosition] = useState(0);
  const [isNext, setIsNext] = useState(true);
  const [isPrev, setIsPrev] = useState(false);

  var bgStyle =
    props.index % 2 ? { background: "#8c62260f" } : { background: "#fff" };

  function hiddenItems() {
    //get hidden items
    var item = document.getElementById("slide" + props.filter);
    var slider = document.getElementById("slider" + props.filter);

    var items = getCount(item, false);
    var visibleItems = slider.offsetWidth / 210;
    console.log(items - Math.ceil(visibleItems));
    return items - Math.ceil(visibleItems);
  }

  function clickPrev() {
    console.log("clickPrev() << : " + position);
    // var position;
    if (position > 0) {
      //avoid slide left beyond the first item
      // position -= 1;
      translateX(position - 1); //translate items
      setPosition(position - 1);
      setIsNext(true);
    } else setIsPrev(false);
    console.log("completed clickPrev() : " + position);
  }

  function clickNext() {
    console.log("clickNext() >> : " + position);
    // var position;
    console.log(" hiddenItems : " + (hiddenItems() + 1) + " , " + position);
    if (position < hiddenItems()) {
      //avoid slide right beyond the last item
      // position += 1;
      translateX(position + 1); //translate items
      // console.log("setPosition : before " + position);
      setPosition(position + 1);
      // console.log("setPosition : " + position);
      setIsPrev(true);
    } else setIsNext(false);
    console.log("completed clickNext() : " + position);
  }

  function translateX(position) {
    //translate items
    console.log("translateX : " + position);
    var slide = document.getElementById("slide" + props.filter);
    slide.style.left = position * -210 + "px";
  }

  function getCount(parent, getChildrensChildren) {
    //count no of items
    var relevantChildren = 0;
    var children = parent.childNodes.length;
    console.log(" children : " + children);
    for (var i = 0; i < children; i++) {
      console.log(
        " parent.childNodes[i].nodeType : " + parent.childNodes[i].nodeType,
        getChildrensChildren
      );
      if (parent.childNodes[i].nodeType !== 3) {
        if (getChildrensChildren)
          relevantChildren += getCount(parent.childNodes[i], true);
        relevantChildren++;
      }
    }
    return relevantChildren;
  }

  return (
    <div style={bgStyle} id={"section-" + props.filter}>
      <div className="section-slider">
        <h2 style={{ paddingLeft: "5px" }}>{props.sliderName}</h2>
        <div className="slider" id={"slider" + props.filter}>
          <div className="slide" id={"slide" + props.filter}>
            {t("Products.Card", { returnObjects: true })
              .filter((item) => item.groupid === props.filter)
              // .filter((item) => console.log(item))
              .map((item) => {
                return (
                  // <div className="item">
                  // <Card
                  //   className="item"
                  //   key={item.id}
                  //   img={item.id}
                  //   title={item.title}
                  //   subtitle={item.description}
                  // />
                  <div style={{margin: "5px"}}>
                    <InfoModal
                      className="item"
                      key={item.id}
                      img={item.id}
                      id={item.id}
                      description={item.description}
                      usage={item.usage}
                      title={item.title}
                      subtitle={item.description}
                      defaultOpen={false}
                      
                    />
                  </div>
                  
                );
              })}
          </div>
        </div>
        {/* <button
          className="ctrl-btn pro-prev"
          onClick={clickPrev}
          disabled={!isPrev}
        >
          &lt;
        </button> */}

        {/* <button
          className="ctrl-btn pro-next"
          onClick={clickNext}
          disabled={!isNext}
        >
          &gt;
        </button> */}
      </div>
    </div>
  );
};

export default Comp;
