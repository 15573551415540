import React, { Component } from "react";
import { Collapse, CardBody, Card, CardHeader, CardImg } from "reactstrap";
import Modal from "./Modal";

class Cards extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: 0, cards: props.cards };
  }
  componentWillReceiveProps(props) {
    this.setState({
      cards: props.cards,
    });
  }
  toggle(e) {
    let event = e.target.dataset.event;
    this.setState({
      collapse: this.state.collapse === event ? 0 : event,
    });
  }
  render() {
    const { cards, collapse } = this.state;
    return (
      <div className="container">
        {cards.map((item) => {
          return (
            <Card style={{ margin: "0 0 1rem 0" }} key={item.id}>
              <CardHeader
                onClick={this.toggle}
                data-event={item.id}
                style={{
                  borderBottom: "none",
                  backgroundColor: "transparent",
                }}
              >
                {item.title}
              </CardHeader>
              <Collapse isOpen={collapse === item.id}>
                <CardBody style={{ textAlign: "center", padding: "0px" }}>
                  <CardImg
                    style={{ width: "226px", paddingBottom: "0.75rem" }}
                    src={require(`./Images/` + item.id + `.png`)}
                    alt={"SANTIMARKETING-" + item.title}
                  />
                  {item.id === "cotton-silk" ? (
                    <div style={{ paddingBottom: "0.75rem" }}>
                      <Modal
                        id="modal-button-detail"
                        buttonLabel="Cotton Silk Mask"
                        className="mask"
                        defaultOpen="false"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {/* {item.description} */}
                </CardBody>
              </Collapse>
            </Card>
          );
        })}
      </div>
    );
  }
}

export default Cards;
