import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap";
import { useTranslation, withTranslation, Trans } from "react-i18next";

const InfoModal = (props) => {
  const { btnStyle, buttonLabel, className, defaultOpen } = props;

  const { t, i18n } = useTranslation();

  const [modal, setModal] = useState(defaultOpen === false ? false : true);

  const toggle = () => setModal(!modal);

  // only close button will close the modal
  const backdrop = "static";

  const styleClose = {
    float: "right",
    fontSize: "5rem",
    fontWeight: "500",
    lineHeight: "1",
    color: "#8D201F",
    opacity: ".5",
    position: "absolute",
    top: "0px",
    right: "25px",
  };

  const styleGlove = {
    text: {
      // maxWidth: "500px",
      marginLeft: "50px"
    },
    img: {
      textAlign: "right"
    }
  }

  const descText = {
    fontWeight: "500",
    borderBottom: "1px solid #8b6226",
    display: "table",
    marginBottom: "10px"
  }

  return (
    <div>
      <Card id={props.key}  onClick={toggle} >
        <CardImg
          top
          width="100%"
          style={{maxWidth: "200px"}}
          src={require(`./Images/` + props.img + `.png`)}
          alt={"SANTIMARKETING-" + props.title}
        />
        <CardBody>
          <CardTitle>
            {props.title.split("\n").map((content) => {
              return <span>{content} <br/></span>
            })}
          </CardTitle>
          {/* <CardSubtitle > </CardSubtitle> */}
          {/* <CardText>{props.text}</CardText> */}
          {/* <Button>Button</Button> */}
        </CardBody>
      </Card>
      <Modal
        id={props.id}
        // backdrop={backdrop}
        isOpen={modal}
        toggle={toggle}
        className={className}
        style={{
          width: "1035px !important",
          maxWidth: "835px",
          height: "100%",
          verticalAlign: "middle",
          alignItems: "center",
          display: "flex",
        }}
      >
        <div className="modal-header" style={{ borderBottom: "none" }}>
          <h5 className="modal-title" style={{margin: "auto"}}> {props.title} </h5>
          <button
            type="button"
            className="close"
            aria-label="Close"
            style={styleClose}
            onClick={toggle}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="infomodal-img">
            <img
                src={require(`./Images/` + props.img + `.png`)}
                className="img-responsive-infomodal"
                // style={{ margin: "0 auto", maxHeight: "500px" }}
                alt="SANTIMARKETING-Cotton Slik Mask"
              />
          </div>
          <div  className="infomodal-text" >
            {props.description && (<div>
              <div style={descText}>{t("Products.description")}</div>
              {props.description.split("\n").map((content) => {
                return <span> { content!== "" && <span>&#8226;</span>} {content} <br/></span>
              })}
            </div>)}
            <br/>
            {props.usage && (<div>
              <div style={descText}>{t("Products.usage")}</div>
              {props.usage.split("\n").map((content) => {
                return <span> {content} <br/></span>
              })}
            </div>)}
          </div>
          
        </div>
      </Modal>
    </div>
  );
};

export default InfoModal;
