import React from "react";

export const TCGlovesPoster = () => {

    return (
        <div className="tc-gloves-poster">
            <div className="tc-gloves-poster text">
                <div className="big-title">ถุงมือ TC</div>
                <h1><span className="">ราคาพิเศษ</span></h1>
                <h1>โหล ละ <span className="circle-sketch-highlight"> 68 บาท </span> </h1>
            </div>
            <div className="image-container">
                <img
                src={require(`./../Images/tcGloves.png`)}
                className="img-responsive tc-gloves"
                alt="santi TC Gloves"
                />
                <div className="contact">
                    <img
                    src={require(`./../Images/line-stm.jpg`)}
                    className="img-responsive "
                    style={{ margin: "0 auto", width: "160px" }}
                    alt="santi official account"
                    />
                </div>
            </div>
        </div>
    )
}