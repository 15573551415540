import React from "react";
import { Jumbotron, Button } from "reactstrap";
import Modal from "./Modal";
import imgEngineer from "./Images/engineer.jpg";
import "./style.css";
import { useTranslation, withTranslation, Trans } from "react-i18next";

export default function() {
  const { t, i18n } = useTranslation();

  const style = {
    backgroundImage: "url(" + imgEngineer + ")",
    position: "relative",
    height: "500px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#fff",
    textAlign: "left"
  };
  const styleText = {
    paddingLeft: "50px"
  };
  return (
    <div style={style}>
      <Jumbotron className="jumbotron-bg-overlay">
        <h1 id="jumbotron-headline" className="jumbotron-text-align">
          {t("Jumbotron.headline")}
        </h1>
        <br />
        {/* <p className="jumbotron-text-align">{t("Jumbotron.description")}</p> */}
        <br />
        <hr className="jumbotron-text-align" />
        <p style={styleText} />
        <br />
        <p className="jumbotron-text-align">
          <a
            className="btn btn-secondary"
            href="mailto:contact@santi-marketing.com"
          >
            {t("Jumbotron.contactButton")}
          </a>
          {/* <Button color="secondary">{t("Jumbotron.contactButton")}</Button> */}
          <span style={{ padding: "0 20px" }}></span>
          <div style={{ display: "inline-block" }} id="modal-button-container">
            <Modal
              id="modal-button"
              buttonLabel="TC Gloves"
              className="mask"
              defaultOpen="false"
            />
          </div>
          {/* <Button color="secondary">{t("Jumbotron.contactButton")}</Button> */}
        </p>
      </Jumbotron>
    </div>
  );
}

// export default jumbotron;
