import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import logo from "./logo.svg";
import Home from "./Pages/home";
import About from "./Pages/about";
import "./App.css";
import { translate, Trans } from "react-i18next";
import Loader from "./Loader";
import Page from "./Page";

function App() {
  return (
    <Router>
      <Switch>
        <Route path={process.env.PUBLIC_URL + "/AboutUs"}>
          <About />
        </Route>

        <Route path={process.env.PUBLIC_URL + "/"}>
          <Suspense fallback={<Loader />}>
            <Home />
          </Suspense>
        </Route>
        <Route path={process.env.PUBLIC_URL + "/:open"}>
          <div>
            hi
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
