import React from "react";
import { render } from "react-dom";
import { Link } from "react-router-dom";
import "./style.css";
import { useTranslation, withTranslation, Trans } from "react-i18next";


const style = {
  display: "block",
  flexShrink: 0,
};
const contactImg = {
  padding: "0.5rem",
};
export default function() {

  const { t, i18n } = useTranslation();
  return (
  <footer className="page-footer font-small blue pt-4">
    <div className="container-fluid text-center text-md-left">
      <div className="row">
        <div className="col-md-5 mt-md-0 mt-3">
          <h5 className="text-uppercase"> {t("Footer.locationTitle")} </h5>
          <p> {t("Footer.location")} </p>
        </div>

        <hr className="clearfix w-100 d-md-none pb-3" />

        <div className="col-md-4 mb-md-0 mb-3">
          <h5 className="text-uppercase">{t("Footer.products")}</h5>

          <ul className="list-unstyled">
            {t("Products.Groups", { returnObjects: true }).map((group) => {
                return (
                  // render dropdown option for each product type.
                  <li><a style={{color: "#fff"}} href={"#section-"+t(group.id)}> {group.name} </a></li>
                  
                );
              })}
            {/* <li>
              <a href="#section-cotton-gloves">Gloves</a>
            </li>
            <li>
              <a href="#section-mask">Mask</a>
            </li>
            <li>
              <a href="#section-etc">Fabric</a>
            </li> */}
          </ul>
        </div>

        <div className="col-md-3 mb-md-0 mb-3">
          <h5 className="text-uppercase">{t("Footer.contact")}</h5>

          <ul
            className="list-unstyled"
            style={{
              display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
            }}
          >
            <li style={contactImg}>
              <a href="tel:029620027">
                {/* {" "}
                contact@santi-marketing.com{" "}
                 */}
                <img
                  height="40"
                  border="0"
                  src={require(`./Images/footer/phone.png`)}
                />
              </a>
            </li>
            <li style={contactImg}>
              <a href="mailto:contact@santi-marketing.com">
                {/* {" "}
                contact@santi-marketing.com{" "}
                 */}
                <img
                  height="40"
                  border="0"
                  src={require(`./Images/footer/mail.svg`)}
                />
              </a>
            </li>
            <li style={contactImg}>
              <a href="https://lin.ee/8KdanxC">
                <img
                  height="40"
                  border="0"
                  src={require(`./Images/footer/line.svg`)}
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div className="footer-copyright text-center py-3">
      SANTI © {new Date().getFullYear()} All rights reserved.
    </div>
  </footer>
  )
};

// export default func;
