import React, { Component, useState } from "react";
import {useLocation} from "react-router-dom";
import Navbar from "../components/Navbar";
import Jumbotron from "../components/Jumbotron";
import Footer from "../components/Footer";
import Card from "../components/Card";
import Modal from "../components/Modal";
import Accordion from "../components/Accordion";
import CardCarousel from "../components/CardCarousel";
import Slider from "../components/Slider";
import About from "./about";
import "../index.css";
import { useTranslation, withTranslation, Trans } from "react-i18next";

// import { Collapse, CardBody, Card, CardHeader, CardImg } from "reactstrap";
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";

const Comp = (props) => {
  const { t, i18n } = useTranslation();
  const search = useLocation().search;
  const name = new URLSearchParams(search).get('name');

  var initProducts = t("Products.Card", { returnObjects: true });

  const [products, setProducts] = useState(initProducts);
  const [position, setPosition] = useState(0);

  function updateLang() {
    initProducts = t("Products.Card", { returnObjects: true });
  }

  function filterList(event) {
    initProducts = t("Products.Card", { returnObjects: true });
    var updatedList = initProducts;
    updatedList = updatedList.filter(function (item) {
      return (
        item.title.toLowerCase().search(event.target.value.toLowerCase()) !==
          -1 ||
        item.description
          .toLowerCase()
          .search(event.target.value.toLowerCase()) !== -1
      );
    });
    setProducts(updatedList);
  }

  function hiddenItems() {
    //get hidden items
    var item = document.getElementById("slide");
    var slider = document.getElementById("slider");

    var items = getCount(item, false);
    var visibleItems = slider.offsetWidth / 210;
    return items - Math.ceil(visibleItems);
  }

  function clickPrev() {
    // var position;
    if (position > 0) {
      //avoid slide left beyond the first item
      // position -= 1;
      translateX(position - 1); //translate items
      setPosition(position - 1);
    }
  }

  function clickNext() {
    // var position;
    if (position < hiddenItems()) {
      translateX(position + 1); //translate items
      setPosition(position + 1);
    }
  }

  function translateX(position) {
    //translate items
    var slide = document.getElementById("slide");
    slide.style.left = position * -210 + "px";
  }

  function getCount(parent, getChildrensChildren) {
    //count no of items
    var relevantChildren = 0;
    var children = parent.childNodes.length;
    for (var i = 0; i < children; i++) {
      if (parent.childNodes[i].nodeType !== 3) {
        if (getChildrensChildren)
          relevantChildren += getCount(parent.childNodes[i], true);
        relevantChildren++;
      }
    }
    return relevantChildren;
  }

  return (
    <div style={{ background: "#fff" }}>
      <Navbar />
      <Jumbotron />
      {/* <div className="section-inner">{/* <CardCarousel /> * /}</div> */}

      {/* <Slider sliderName={"group.name"} filter={"mask"}></Slider> */}
      {t("Products.Groups", { returnObjects: true }).map((group, index) => {
        return (
          <Slider
            sliderName={group.name}
            filter={group.id}
            index={index}
          ></Slider>
        );
      })}
      <About />
      <Footer />
    </div>
  );
};

export default Comp;
