import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { TCGlovesPoster } from "./Custom/TCGlovesPoster";

const ModalExample = (props) => {
  const { btnStyle, buttonLabel, className, defaultOpen } = props;

  const [modal, setModal] = useState(defaultOpen === 'false' ? false : true);

  const toggle = () => setModal(!modal);

  // only close button will close the modal
  const backdrop = "static";

  const styleClose = {
    float: "right",
    fontSize: "5rem",
    fontWeight: "500",
    lineHeight: "1",
    color: "#8D201F",
    opacity: ".5",
    position: "absolute",
    top: "0px",
    right: "25px",
  };

  return (
    <div>
      <Button color="info" onClick={toggle} style={btnStyle}>
        {buttonLabel}
      </Button>
      <Modal
        id={props.id}
        // backdrop={backdrop}
        isOpen={modal}
        toggle={toggle}
        className={className}
        style={{
          width: "800px !important",
          maxWidth: "800px",
          height: "100%",
          verticalAlign: "middle",
          alignItems: "center",
          display: "flex",
        }}
      >
        <div className="modal-header" style={{ borderBottom: "none" }}>
          
          <TCGlovesPoster />
          <button
            type="button"
            className="close"
            aria-label="Close"
            style={styleClose}
            onClick={toggle}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ModalExample;
